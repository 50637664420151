import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";

import projectphoto1 from "../images/projects/Project1/1.jpg";
import projectphoto2 from "../images/projects/Project1/2.jpg";
import projectphoto3 from "../images/projects/Project1/3.jpg";
import projectphoto4 from "../images/projects/Project1/4.jpg";
import projectphoto5 from "../images/projects/Project1/5.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>Urbanist Office Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          The Urbanist office interiors project reflects a seamless blend of modern design with functional workspace solutions. Our team meticulously crafted every element of the space to enhance productivity while maintaining an inviting atmosphere. Located in the bustling city of Dubai, this office stands as a testament to our ability to create sophisticated environments that align with our client's brand and culture.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="Marquette County Medical Care Facility"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          As part of the master plan, we designed an adaptable workspace that
          includes open-plan areas, private offices, and collaborative zones.
          This flexible approach allows the Urbanist office to accommodate
          various work styles, from focused individual tasks to team-based
          projects.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Contemporary Reception Area"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The contemporary reception area was designed with a blend of modern
          elegance and functionality. This space sets the tone for the entire
          office, welcoming visitors with its sleek design and carefully
          selected materials that convey a sense of professionalism and warmth.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Dynamic Workspace"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The dynamic workspace is designed to foster creativity and
          productivity. With an open layout and flexible furniture options, this
          area supports both individual focus and team collaboration, making it
          ideal for a fast-paced and innovative work environment.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The master plan also included the creation of versatile breakout areas
          and quiet rooms, offering employees spaces to recharge or concentrate
          away from their desks. The design incorporates natural elements, such
          as greenery and sunlight, to enhance the overall ambiance and promote
          well-being.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <Link to="/services" aria-label="Services">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>
          </div>
          <div className="uniqueDZUreZ">
            <Link to="/about" aria-label="About Us">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage1 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage1;
