import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom"; 
import projectphoto1 from "../images/projects/Project8/1.jpg";
import projectphoto2 from "../images/projects/Project8/2.jpg";
import projectphoto3 from "../images/projects/Project8/3.jpg";
import projectphoto4 from "../images/projects/Project8/4.jpg";
import projectphoto5 from "../images/projects/Project8/6.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>Lead Office Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          The Lead office interiors project presents a refined and modern workspace designed to cater to high-performance work environments. With a focus on maximizing natural light and incorporating high-end materials, this space is both elegant and functional, providing an ideal setting for business operations in Dubai.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="Lead Office Interior"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The facility master plan for Lead's office was driven by the goal of creating a workspace that promotes productivity while exuding sophistication. The design features open work areas, private offices, and meeting rooms that cater to various work styles and functions.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Executive Office with Panoramic View"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The executive office is designed to impress with its panoramic views of the city, complemented by sleek, minimalist furnishings. The large windows allow natural light to flood the space, enhancing the work environment while providing stunning views of Dubai.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Sophisticated Meeting Rooms"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The meeting rooms are designed for both functionality and style. Equipped with state-of-the-art technology and comfortable seating, these rooms provide the perfect setting for high-level discussions and decision-making.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The design also includes collaborative spaces and a sleek, modern reception area, ensuring that every visitor's first impression of Lead’s office is one of professionalism and excellence. The combination of wood, glass, and metal finishes adds to the overall sophistication of the workspace.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            <Link to="/services" aria-label="Services">
              {" "}
              {/* Changed to Link */}
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>{" "}
            {/* Link tag closed */}
          </div>
          <div className="uniqueDZUreZ">
            <Link to="/about" aria-label="About Us">
              {" "}
              {/* Changed to Link */}
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>{" "}
            {/* Link tag closed */}
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage8 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage8;
