import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";

import projectphoto1 from "../images/projects/Project3/1.jpg";
import projectphoto2 from "../images/projects/Project3/2.jpg";
import projectphoto3 from "../images/projects/Project3/3.jpg";
import projectphoto4 from "../images/projects/Project3/4.jpg";
import projectphoto5 from "../images/projects/Project3/5.jpg";

const ProjectHero = () => {
  return (
    <section className="project-hero">
      <div className="hero-content">
        <h1>SDY Corporate Office Interiors</h1>
        <span className="location">Dubai, UAE</span>
        <p>
          The SDY Corporate Office project is a showcase of refined design that
          emphasizes luxury, functionality, and innovation. Our team crafted
          this workspace to reflect the company’s vision of a modern, efficient,
          and sophisticated environment that supports both collaboration and
          privacy.
        </p>
      </div>
      <div className="hero-image-container">
        <img
          src={projectphoto1}
          alt="SDY Corporate Office Dubai"
          className="hero-image"
        />
      </div>
    </section>
  );
};

const FacilityMasterplan = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The SDY Corporate Office was designed with flexibility in mind,
          featuring a variety of workspaces that cater to different work styles.
          From open-plan workstations to enclosed private offices, the layout
          supports both team collaboration and individual focus.
        </p>
      </div>
    </section>
  );
};

const ProjectSection = () => {
  return (
    <section className="section-sc-1vjgz5n-0 eeeNzp">
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu">
        <div className="ratio-img-wrapper-sc-8qesjk-1 project-image-sc-di9xrj-0">
          <img
            alt="Executive Meeting Room"
            className="ratio-img-sc-8qesjk-0 dwEpAz"
            src={projectphoto2}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-1">
          The executive meeting room is a masterpiece of minimalist design. With
          state-of-the-art technology seamlessly integrated into the space, it
          offers a professional yet comfortable atmosphere for high-stakes
          discussions. The use of natural wood and glass elements adds warmth
          and openness to the room.
        </p>
      </div>
      <div className="container-sc-1uyjznq-0 eBZEyY bbJFvu reverse">
        <div className="ratio-img-wrapper-sc-8qesjk-2 project-image-sc-di9xrj-0">
          <img
            alt="Private Workspace"
            className="ratio-img-sc-8qesjk-0 dwEpAz2"
            src={projectphoto3}
          />
        </div>
        <p className="project-image-copyblock-content-sc-di9xrj-2">
          The private workspace is designed to provide a tranquil environment
          for focused work. The space features high-end materials, ergonomic
          furniture, and ample natural light, ensuring that employees can work
          comfortably and efficiently.
        </p>
      </div>
    </section>
  );
};

const FacilityMasterplan2 = () => {
  return (
    <section className="section-wrapper">
      <div className="section-content">
        <p>
          The office also includes versatile breakout areas, equipped with
          contemporary furniture and a calming color palette. These spaces are
          ideal for informal meetings or simply taking a break, enhancing both
          productivity and employee well-being.
        </p>
      </div>
    </section>
  );
};

const UniqueSection = () => {
  return (
    <section className="uniqueBeNxCw">
      <div className="uniqueEBZEyY">
        <div className="uniqueECjdAA">
          <h2 className="uniqueFLhVRG">Explore More About Us</h2>
        </div>
        <div className="uniqueWOhqI">
          <div className="uniqueDZUreZ">
            {/* Use Link component for navigation */}
            <Link to="/services" aria-label="Services">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto4}
                    alt="Our Services"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Discover Our Services</h3>
                <span className="uniqueJxeAbl">View Services</span>
              </div>
            </Link>
          </div>
          <div className="uniqueDZUreZ">
            {/* Use Link component for navigation */}
            <Link to="/about" aria-label="About Us">
              <div className="uniqueEEDwbc">
                <div className="uniqueBlhpmN">
                  <img
                    src={projectphoto5}
                    alt="About Us"
                    className="uniqueDWepAz"
                  />
                </div>
              </div>
              <div className="uniqueCoZWpe">
                <h3 className="uniqueStwfE">Learn About Our Journey</h3>
                <span className="uniqueJxeAbl">Who We Are</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectPage4 = () => {
  return (
    <>
      <ProjectHero />
      <FacilityMasterplan />
      <ProjectSection />
      <FacilityMasterplan2 />
      <UniqueSection />
    </>
  );
};

export default ProjectPage4;
